<template>
  <div class="mb-2 font-semibold text-gray-900" :class="[levelClasses[level]]">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
type LevelOptions = 'h1' | 'h2' | 'h3' | 'h4';

defineProps<{
  level: LevelOptions;
}>();

const levelClasses: Record<LevelOptions, string> = {
  h1: 'text-3xl mt-6',
  h2: 'text-2xl mt-5',
  h3: 'text-xl mt-4',
  h4: 'text-lg mt-3',
};
</script>

<style></style>
